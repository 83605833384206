<template>
  <div class="ls-page">
    <div class="ps-section__header p-0">
      <h1>Dashboard</h1>
    </div>

    <div class="ps-section__content ls-page-content">
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <router-link :to="{ name: 'admin_orders' }">
            <div class="card ls-admin-card">
              <div class="card-body">
                <div class="card-text ls-center">
                  {{ ordersCount }}
                </div>
              </div>
              <div class="card-footer ls-center">
                Orders
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <router-link :to="{ name: 'admin_payments' }">
            <div class="card ls-admin-card">
              <div class="card-body">
                <div class="card-text ls-center">
                  {{ totalPayments }}
                </div>
              </div>
              <div class="card-footer ls-center">
                Total Payments
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <router-link :to="{ name: 'admin_users', params: {type: 'customers'}}">
            <div class="card ls-admin-card">
              <div class="card-body">
                <div class="card-text ls-center">
                  {{ totalCustomers }}
                </div>
              </div>
              <div class="card-footer ls-center">
                Total Customers
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from "axios";
import {mapState} from "vuex";

export default {
  name: "AdminDashboard",
  mounted() {
    this.getDashboard();
  },
  data() {
    return {
      ordersCount: 0,
      totalPayments: 0,
      totalCustomers: 0,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  methods: {
    getDashboard() {
    },
  },
}
</script>

<style lang="css" scoped>
#sidebarMenu {
  height: 1000px;
}
</style>